import { Box, LoadingOverlay, Stack, useMantineTheme } from "@mantine/core";
import {
  Tooltip,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { DcaProps } from "./Interface";
import moment from "moment";
import { useResizeObserver } from "@mantine/hooks";
import { Card } from "../../components/Card";

interface GraphProps {
  dca: DcaProps;
  loading: boolean;
  isMobile: boolean;
}

export const Graph = ({ dca, loading, isMobile }: GraphProps) => {
  const [ref, rect] = useResizeObserver();
  const theme = useMantineTheme();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formateValue = (value: number) => {
    return formatter.format(value).slice(0, -3);
  };

  const formatTooltip = (value: any, name: any) => {
    switch (name) {
      case "price":
        return [formateValue(value), "BTC Price"];
      default:
        return [value, name];
    }
  };

  const data = dca?.dca?.map((item, i) => {
    return {
      name: moment(item["date"]).format("MMM YY"),
      price: item["price"],
      amount: dca.dca?.reduce(
        (acc, buy, j) => acc + (j < i ? buy["amount"] * buy["price"] : 0),
        0
      ),
    };
  });

  return (
    <Box style={{ position: "relative" }}>
      {loading && <LoadingOverlay visible={loading} />}
      <Box ref={ref} p="md">
        <ResponsiveContainer width={Math.round(rect.width)} height={400}>
          <LineChart data={data}>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip formatter={formatTooltip} />
            <XAxis dataKey="name" />
            <YAxis
              allowDecimals={false}
              dataKey="price"
              label={{
                value: "BTC Price",
                angle: -90,
                position: "insideBottom",
                textAnchor: "middle",
              }}
              tickFormatter={formateValue}
            />
            <Line
              type="monotone"
              dataKey="price"
              stroke={theme.colors.orange[6]}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
