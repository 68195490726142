const axios = require("axios");
const baseUrl = process.env.REACT_APP_API_URL;

export const getFAQPage = (id: string) =>
  axios.get(`${baseUrl}/webflow/faq/${id}`);
export const voteOnFAQ = (id: string | undefined, vote: number, type: string) =>
  axios.post(`${baseUrl}/webflow/faq/${id}/vote`, { vote, type });
export const getBitcoinPrice = async () => {
  const btcUsdPrice = localStorage.getItem("btcUsdPrice");
  if (
    btcUsdPrice !== null &&
    Date.now() - JSON.parse(btcUsdPrice).timestamp < 60000
  ) {
    return JSON.parse(btcUsdPrice);
  }

  const { data } = await axios.get(
    `https://api.coingecko.com/api/v3/coins/bitcoin/`
  );

  const newBtcUsdPrice = {
    price: data.market_data.current_price.usd,
    timestamp: Date.now(),
  };
  localStorage.setItem("btcUsdPrice", JSON.stringify(newBtcUsdPrice));

  return newBtcUsdPrice;
};
