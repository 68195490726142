import { formatCurrency } from "@coingecko/cryptoformat";

export const formatDollar = (amount: number) => {
  return formatCurrency(amount, "usd");
};
export const formatBitcoin = (amount: number) => {
  return formatCurrency(amount, "BTC", "en", false, {
    decimalPlaces: 8,
  }).slice(1);
};
