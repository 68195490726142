import {
  Box,
  Title,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  Button,
} from "@mantine/core";
import React from "react";
import { DcaProps, FREQ } from "./Interface";
import { Card } from "../../components/Card";
import { MaxAmount } from "../../components/MaxAmount";

interface OptionProps {
  dca: DcaProps;
  dispatch: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  isMobile: boolean;
  influencer?: string;
  campaign?: string;
}

export const Options = ({ dca, dispatch, loading, campaign }: OptionProps) => {
  const frequencies = [
    { label: "Daily", value: String(FREQ.DAILY) },
    { label: "Weekly", value: String(FREQ.WEEKLY) },
    { label: "Bi-Weekly", value: String(FREQ.BI_WEEKLY) },
    { label: "Monthly", value: String(FREQ.MONTHLY) },
  ];

  const years = [
    { label: "1 year ago", value: "1" },
    { label: "3 years ago", value: "3" },
    { label: "5 years ago", value: "5" },
    { label: "10 years ago", value: "10" },
  ];
  //handle years ago
  const handleDateChange = (year: string) => {
    dispatch({
      type: "UPDATE",
      payload: {
        prop: "years",
        value: Number(year),
      },
    });
  };

  const onCtaClick = (e: any) => {
    e.preventDefault();
    window.location.href = `https://my.${window.location.hostname}/auth/email${
      campaign ? `?utm_campaign=${campaign}` : ""
    }`;
  };

  return (
    <Box style={{ position: "relative", height: "100%" }}>
      {loading && <LoadingOverlay visible={loading} />}
      <Card style={{ height: "100%" }} border={false}>
        <Stack
          justify="space-between"
          spacing={"lg"}
          style={{ height: "100%" }}
        >
          <Stack spacing={"xl"}>
            <MaxAmount
              amount={dca.amount}
              range={[10, 20, 50, 100]}
              onChange={(value) =>
                dispatch({ type: "UPDATE", payload: { prop: "amount", value } })
              }
            />
            <Stack>
              <Select
                size="md"
                label={
                  <Text size="sm" color="gray">
                    Time interval
                  </Text>
                }
                name="frequency"
                data={frequencies}
                value={String(dca.frequency)}
                sx={(theme) => ({
                  fontSize: theme.fontSizes.sm,
                })}
                onChange={(value) =>
                  dispatch({
                    type: "UPDATE",
                    payload: { prop: "frequency", value },
                  })
                }
              />

              <Select
                size="md"
                label={
                  <Text size="sm" color="gray">
                    Start date
                  </Text>
                }
                name="data"
                data={years}
                value={String(dca.years)}
                sx={(theme) => ({
                  input: {
                    fontSize: theme.fontSizes.sm,
                  },
                })}
                onChange={handleDateChange}
              />
            </Stack>
          </Stack>

          <Stack spacing={"sm"} align="center">
            <Button
              onClick={onCtaClick}
              size="md"
              fullWidth
              sx={{ height: 50 }}
            >
              Sign up to Coinbits
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};
