import {
  Alert,
  Group,
  LoadingOverlay,
  Skeleton,
  Text,
  Title,
} from "@mantine/core";
import React from "react";
import { DcaProps } from "./Interface";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";

interface StatsProps {
  dca: DcaProps;
  loading?: boolean;
}

export const Stats = ({ dca, loading }: StatsProps) => {
  const formatToUSNumber = (value: number) =>
    value && value.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  const totalInBTC = dca?.dca?.reduce((acc, item) => acc + item["amount"], 0);
  const totalInvested = dca?.dca?.reduce(
    (acc, item) => acc + item["amount"] * item["price"],
    0
  );
  const totalBTCToday =
    totalInBTC && totalInBTC * dca?.prices?.[dca.prices?.length - 1][1];
  const precentageChange =
    ((totalBTCToday - totalInvested) / totalInvested) * 100;

  return (
    <Alert color={"gray"}>
      {loading && (
        <Skeleton width={"70%"} height={20} sx={{ margin: "0 auto" }} />
      )}
      {dca && !loading && (
        <Group noWrap align={"center"} position="center">
          <Title
            order={4}
            sx={(theme) => ({
              flexGrow: 0,
              display: "inline-flex",
              alignItems: "center",
              path: {
                strokeWidth: "3px !important",
              },
              color:
                precentageChange > 0
                  ? theme.colors.green[7]
                  : theme.colors.red[7],
            })}
          >
            {precentageChange > 0 ? (
              <HiOutlineArrowUp size={20} />
            ) : (
              <HiOutlineArrowDown size={20} />
            )}
            {Math.abs(precentageChange).toFixed(2)}%
          </Title>
          <Text size="sm" align="left">
            Your{" "}
            <b>
              <span className="coinbits-symbol">₿</span>
              {totalInBTC.toFixed(8)}
            </b>{" "}
            bitcoin would be worth <b>${formatToUSNumber(totalBTCToday)}</b>{" "}
            today, and it would have cost you{" "}
            <b>${formatToUSNumber(totalInvested)}</b>.
          </Text>
        </Group>
      )}
    </Alert>
  );
};
