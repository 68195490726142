import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";

const cbComponents = document.querySelectorAll(".cb_widget");

// Inject our React App into each
cbComponents.forEach((Div) => {
  const root = ReactDOM.createRoot(Div);
  root.render(
    <React.StrictMode>
      <App element={Div} />
    </React.StrictMode>
  );
});
