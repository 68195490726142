import { MantineTheme, MantineThemeOverride } from "@mantine/core";

export const GREEN_COLOR = "#03A678";
export const RED_COLOR = "#EB2135";
export const GREY_COLOR = "#4E5A68";

export const theme: MantineThemeOverride = {
  black: "#152536",
  colors: {
    black: [
      "#e8ebef",
      "#ccd4db",
      "#b1bcc8",
      "#95a5b4",
      "#7a8da1",
      "#5e768d",
      "#435e7a",
      "#395068",
      "#2f4356",
      "#263545",
    ],
    blue: [
      "#e1eff9",
      "#bdddf3",
      "#99cbec",
      "#75b8e5",
      "#52a6de",
      "#2e93d8",
      "#0a81d1",
      "#096eb2",
      "#075b94",
      "#064875",
    ],
    gray: [
      "#ECF0F5",
      "#E1E8EF",
      "#D7E0EA",
      "#CCD8E4",
      "#C2D0DF",
      "#7A8DA1",
      "#435E7A",
      "#435E7A",
      "#2F4356",
      "#152536",
    ],
    green: [
      "#E6F6F2",
      "#B3E4D7",
      "#9ADBC9",
      "#81D3BC",
      "#4FC1A1",
      "#35B893",
      "#03A678",
      "#03956C",
      "#027454",
      "#02533C",
    ],
    orange: [
      "#fff4eb",
      "#ffe7d3",
      "#ffdabb",
      "#ffcca3",
      "#ffbf8c",
      "#ffb274",
      "#ffa55c",
      "#da8d4f",
      "#b57541",
      "#8f5d34",
    ],
    red: [
      "#fde3e6",
      "#fac3c8",
      "#f7a3ab",
      "#f4828d",
      "#f16270",
      "#ee4152",
      "#eb2135",
      "#c91c2d",
      "#a61726",
      "#84131e",
    ],
    yellow: [
      "#fff8e4",
      "#fff0c3",
      "#ffe8a3",
      "#ffe083",
      "#ffd863",
      "#ffd042",
      "#ffc822",
      "#daab1d",
      "#b58e18",
      "#8f7013",
    ],
  },
  fontFamily: "CoinbitsCircular, sans-serif",
  headings: {
    fontFamily: "CoinbitsCircular, sans-serif",
    fontWeight: 500,
  },
  primaryColor: "green",
};

export const inputIconProps = {
  color: theme.colors?.grey?.[7],
  size: 18,
};

export const globalTheme = (theme: MantineTheme) => ({
  ".mantine-Button-root": {
    "&:active": {
      transform: "initial !important",
    },
    "&.mantine-Button-filled:active": {
      color: theme.white,
    },
  },
  ".mantine-Checkbox-root": {
    alignItems: "flex-start",
    input: {
      cursor: "pointer",
      "&[error]": {
        borderColor: theme.colors.red[6],
      },
    },
    label: { cursor: "pointer" },
  },
  ".mantine-Input-invalid": {
    borderColor: theme.colors.red[6],
  },
});
