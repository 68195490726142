import { MantineTheme, MantineThemeOverride } from "@mantine/core";

export const GREEN_COLOR = "#03A678";
export const RED_COLOR = "#EB2135";
export const GREY_COLOR = "#4E5A68";

export const theme: MantineThemeOverride = {
  black: "#12141d",
  colors: {
    black: [
      "#F1F2F4",
      "#D4D9DE",
      "#C5CCD3",
      "#A8B2BD",
      "#8B99A7",
      "#6E7F91",
      "#12181F",
      "#0E1319",
      "#0B0E13",
      "#070A0C",
    ],
    blue: [
      "#e1eff9",
      "#bdddf3",
      "#99cbec",
      "#75b8e5",
      "#52a6de",
      "#2e93d8",
      "#0a81d1",
      "#096eb2",
      "#075b94",
      "#064875",
    ],
    gray: [
      "#F5F4F2",
      "#EAE8E4",
      "#E0DDD7",
      "#D5D2C9",
      "#C1BBAF",
      "#ACA594",
      "#978E79",
      "#5B5549",
      "#3C3930",
      "#2D2B24",
    ],
    green: [
      "#E6F6F2",
      "#B3E4D7",
      "#9ADBC9",
      "#81D3BC",
      "#4FC1A1",
      "#35B893",
      "#03A678",
      "#03956C",
      "#027454",
      "#02533C",
    ],
    orange: [
      "#fff4eb",
      "#ffe7d3",
      "#ffdabb",
      "#ffcca3",
      "#ffbf8c",
      "#ffb274",
      "#ffa55c",
      "#da8d4f",
      "#b57541",
      "#8f5d34",
    ],
  },
  fontFamily: "CoinbitsCircular, sans-serif",
  fontSizes: { xs: 14, sm: 16, md: 18, lg: 20, xl: 28 },
  headings: {
    fontFamily: "CoinbitsCircular, sans-serif",
    sizes: {
      h1: { fontSize: 34 },
      h2: { fontSize: 28 },
      h3: { fontSize: 24 },
      h4: { fontSize: 20 },
      h5: { fontSize: 18 },
      h6: { fontSize: 16 },
    },
  },
  primaryColor: "black",
  radius: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 },
  spacing: { xs: 4, sm: 8, md: 16, lg: 24, xl: 32 },
};
