import {
  Button,
  Grid,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useReducer, useState } from "react";
import { Graph } from "./Graph";
import { Options } from "./Options";
import { Action, DcaProps, FREQ } from "./Interface";
import { useMediaQuery } from "@mantine/hooks";
import { Stats } from "./Stats";
import moment from "moment";

const dcaInit: DcaProps = {
  amount: 10,
  frequency: FREQ.WEEKLY,
  years: 3,
  logarithm: false,
  today: new Date(),
  prices: [],
  dca: [],
};

const reducer = (state: DcaProps, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE":
      return {
        ...state,
        [payload.prop]: payload.value,
      };
    case "CALCULATE":
      return {
        ...state,
        [payload.prop]: payload.value,
      };
    case "RESET": {
      return { ...dcaInit };
    }
    default:
      return state;
  }
};

export const Dca = ({
  influencer,
  campaign,
}: {
  influencer?: string;
  campaign?: string;
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [dca, dispatch] = useReducer(reducer, dcaInit);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const filteredPrices = dca?.prices?.filter((price, index) => {
    const date = moment(price[0]);
    return (
      date.isAfter(moment(dca.today).subtract(dca.years, "years")) &&
      date.isBefore(dca.today) &&
      index % dca.frequency === 0
    );
  });

  const calculateDca = () => {
    const calcDca = filteredPrices?.reduce((acc, price) => {
      return [
        ...acc,
        {
          date: price[0],
          amount: (1 / price[1]) * dca.amount,
          price: price[1],
        },
      ];
    }, [] as any[]);
    dispatch({
      type: "UPDATE",
      payload: { prop: "dca", value: calcDca },
    });
  };

  const getCoinPrices = async () => {
    const localPrices = localStorage.getItem("coinbits-btc-prices");
    const localDate = localStorage.getItem("coinbits-today");
    if (localPrices && localDate === moment(dca.today).format("YYYY-MM-DD")) {
      return JSON.parse(localPrices);
    }
    const response = await fetch(
      `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart/range/?vs_currency=usd&from=${moment(
        "2013-01-01"
      ).unix()}&to=${moment(dca.today).unix()}`
    );
    const { prices } = await response.json();
    localStorage.setItem(
      "coinbits-today",
      moment(dca.today).format("YYYY-MM-DD")
    );
    localStorage.setItem("coinbits-btc-prices", JSON.stringify(prices));
    return prices;
  };

  useEffect(() => {
    setIsLoading(true);
    if (dca?.prices?.length) {
      calculateDca();
      setIsLoading(false);
      return;
    }
    (async () => {
      try {
        const prices = await getCoinPrices();
        dispatch({
          type: "UPDATE",
          payload: { prop: "prices", value: prices },
        });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dca.amount, dca.frequency, dca.years, dca.prices]);

  if (!isLoading && dca?.prices?.length === 0) {
    return (
      <Stack align="center" p="lg">
        <Title order={2} align="center">
          Oops, we're sorry.
        </Title>
        <Text size="sm" color="gray" align="center">
          It looks there was an issue.
        </Text>
        <Button
          component="a"
          variant="light"
          href="mailto:support@coinbits.app"
        >
          Contact Support
        </Button>
      </Stack>
    );
  }

  return (
    <Grid p={isMobile ? "md" : "lg"} gutter={"lg"}>
      <Grid.Col span={isMobile ? 12 : 4}>
        <Options
          influencer={influencer}
          campaign={campaign}
          dca={dca}
          dispatch={dispatch}
          loading={isLoading}
          isMobile={isMobile}
        />
      </Grid.Col>
      <Grid.Col span={isMobile ? 12 : 8}>
        <Stats dca={dca} loading={isLoading} />
        <Graph dca={dca} loading={isLoading} isMobile={isMobile} />
      </Grid.Col>
    </Grid>
  );
};
