import {
  Card,
  Group,
  NumberInput,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Timeline,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { getBitcoinPrice } from "../../api/api";
import { CbAlmostEqual, CbBitcoin, CbDollar } from "../../components/icons";
import { formatBitcoin, formatDollar } from "../../helpers";

export default function Quote() {
  const [quote, setQuote] = useState<number>();

  const [usd, setUsd] = useState<number>(50);
  const [btc, setBtc] = useState<number>();

  useEffect(() => {
    (async () => {
      try {
        const btcUsd: any = await getBitcoinPrice();
        setQuote(btcUsd.price);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (quote) {
      setBtc(usd / quote);
    }
  }, [usd, quote]);

  return (
    <Card>
      <Stack spacing="xl">
        <Timeline active={3} lineWidth={2}>
          <Timeline.Item
            bullet={<CbDollar size={24} />}
            bulletSize={42}
            lineVariant="dashed"
            lineActive={false}
            sx={{
              "&::before": {
                top: 10,
              },
            }}
          >
            <LineItemContainer>
              <NumberInput
                size="md"
                value={usd}
                onChange={(value: number) => setUsd(value <= 0 ? 0 : value)}
                decimalSeparator="."
                color="green"
                min={0}
                rightSection={<></>}
                sx={(theme) => ({
                  width: "100%",
                  input: {
                    fontSize: "1.75rem",
                    color: theme.colors.green[7],
                    height: 50,
                    fontWeight: 500,
                  },
                })}
              />
            </LineItemContainer>
          </Timeline.Item>
          <Timeline.Item
            bullet={<HiOutlineSwitchHorizontal size={20} />}
            lineVariant="dashed"
            bulletSize={28}
          >
            <LineItemContainer>
              <Text size="lg">1 BTC</Text>
              <ThemeIcon color="gray" radius="xl" size="md" variant="light">
                <CbAlmostEqual size={14} strokeWidth={3} />
              </ThemeIcon>
              {quote ? (
                <Text size="lg">{formatDollar(quote)}</Text>
              ) : (
                <Skeleton width={100} height={10} />
              )}
            </LineItemContainer>
          </Timeline.Item>
          <Timeline.Item
            bullet={<CbBitcoin size={28} />}
            color={"orange"}
            bulletSize={42}
            lineVariant="dashed"
          >
            <LineItemContainer>
              {btc ? (
                <Title
                  order={2}
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {formatBitcoin(btc)}
                </Title>
              ) : (
                <Skeleton width={100} height={20} />
              )}
            </LineItemContainer>
          </Timeline.Item>
        </Timeline>
      </Stack>
    </Card>
  );
}

const LineItemContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Group
      pl="sm"
      sx={{
        height: 45,
      }}
      align="center"
      spacing={"sm"}
    >
      {children}
    </Group>
  );
};
