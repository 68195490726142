import {
  Alert,
  Button,
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
} from "@mantine/core";
import React from "react";

interface MaxAmountProps {
  amount?: number;
  range?: number[] | undefined;
  note?: React.ReactNode | null;
  onChange?: (value: number) => void;
}

export const MaxAmount: React.FC<MaxAmountProps> = ({
  amount = 0,
  range,
  note,
  onChange = () => {},
}) => {
  return (
    <Stack spacing={"lg"}>
      <NumberInput
        size="xl"
        placeholder="Enter purchase amount"
        min={10}
        max={100000}
        hideControls
        value={amount}
        defaultValue={10}
        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
        formatter={(value: any) =>
          !Number.isNaN(parseFloat(value))
            ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "$ "
        }
        onChange={onChange}
      />
      {note && (
        <Alert
          color="gray"
          sx={(theme) => {
            return {
              padding: theme.spacing.xs,
              textAlign: "center",
              fontSize: theme.fontSizes.sm,
            };
          }}
        >
          {note}
        </Alert>
      )}
      {range && (
        <Group grow spacing="sm" sx={{ height: 40 }}>
          {range.map((item) => (
            <Button
              variant={amount !== item ? "light" : undefined}
              color={amount !== item ? "gray" : undefined}
              radius="xl"
              size="sm"
              px={0}
              key={`amount-btn-${item}`}
              onClick={() => onChange(item)}
            >
              ${item}
            </Button>
          ))}
        </Group>
      )}
    </Stack>
  );
};
