import { Icon, IconProps } from "../icon";

export function CbBitcoin({ fill, ...rest }: IconProps) {
  return (
    <Icon strokeWidth={0} {...rest}>
      <path
        d="M19.5769 9.84442C19.9228 7.53274 18.1627 6.29004 15.756 5.46103L16.5367 2.32957L14.6306 1.85453L13.8705 4.90346C13.3694 4.7786 12.8547 4.66079 12.3433 4.54407L13.1088 1.47504L11.2038 1L10.4225 4.13036C10.0078 4.0359 9.60059 3.94253 9.20536 3.84427L9.20753 3.83449L6.57881 3.17812L6.07175 5.214C6.07175 5.214 7.486 5.53811 7.45614 5.5582C8.22814 5.75093 8.36766 6.26179 8.34432 6.6668L7.45505 10.2342C7.50825 10.2478 7.57721 10.2673 7.65322 10.2977C7.5897 10.282 7.52183 10.2646 7.4518 10.2478L6.20529 15.2452C6.11082 15.4797 5.87141 15.8315 5.33177 15.698C5.35077 15.7257 3.94628 15.3521 3.94628 15.3521L3 17.5341L5.48052 18.1524C5.94198 18.2681 6.39421 18.3891 6.83939 18.5031L6.05057 21.6704L7.95452 22.1455L8.73575 19.0118C9.25585 19.153 9.76075 19.2833 10.2548 19.406L9.47628 22.525L11.3824 23L12.1712 19.8387C15.4216 20.4538 17.8657 20.2057 18.8945 17.2659C19.7235 14.8988 18.8533 13.5334 17.1431 12.6431C18.3885 12.3559 19.3267 11.5366 19.5769 9.84442ZM15.2218 15.9515C14.6327 18.3186 10.6473 17.0389 9.3552 16.7181L10.4019 12.522C11.694 12.8445 15.8374 13.4829 15.2218 15.9515ZM15.8114 9.8102C15.2739 11.9633 11.9568 10.8694 10.8808 10.6012L11.8298 6.79548C12.9058 7.06368 16.3711 7.56422 15.8114 9.8102Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export function CbAlmostEqual(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M21.9939 7.23223C19.3305 10.0883 14.8561 10.2446 12 7.58122C9.14388 4.91785 4.66946 5.07411 2.00609 7.93022" />
      <path d="M21.9939 16.0698C19.3305 18.9259 14.8561 19.0822 12 16.4188C9.14388 13.7554 4.66946 13.9117 2.00609 16.7678" />
    </Icon>
  );
}
export function CbDollar(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M12.5 4.66667C9.46235 4.66667 7 6.30829 7 8.33333C7 10.3584 9.46235 12 12.5 12C15.5377 12 18 13.6416 18 15.6667C18 17.6918 15.5377 19.3333 12.5 19.3333M12.5 4.66667C14.5357 4.66667 16.3131 5.40403 17.2643 6.5M12.5 4.66667V1M12.5 19.3333V23M12.5 19.3333C10.4643 19.3333 8.68681 18.596 7.73585 17.5" />
    </Icon>
  );
}
