import React from "react";
import "./theme/styles.css";
import { Dca } from "./structures/dca/Dca";
import { Voting } from "./structures/voting/Voting";
import { AppElement } from "./interfaces/Interfaces";
import { Global, MantineProvider } from "@mantine/core";
import { globalTheme, theme } from "./mantine";
import Quote from "./structures/quote/Quote";

export const App: React.FC<AppElement> = ({ element }) => {
  const component = element.getAttribute("data-component") || "dca";
  const campaign = element.getAttribute("data-campaign") || "";
  const name = element.getAttribute("data-theme") || "coinbits";

  return (
    <MantineProvider theme={theme(name)} withNormalizeCSS>
      <Global styles={globalTheme} />
      <div className="App">
        {component === "voting" && <Voting />}
        {component === "quote" && <Quote />}
        {component === "dca" && <Dca influencer={name} campaign={campaign} />}
      </div>
    </MantineProvider>
  );
};
