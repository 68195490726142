import { Moment } from "moment";

export enum FREQ {
  DAILY = 1,
  WEEKLY = 7,
  BI_WEEKLY = 14,
  MONTHLY = 30,
  YEARLY = 365,
}

export type Frequency = FREQ.DAILY | FREQ.WEEKLY | FREQ.BI_WEEKLY | FREQ.YEARLY;
export type State = "UPDATE" | "RESET" | "CALCULATE";
export type Range = 6 | 12 | 24 | 36 | 60;
export type Action = { type: State; payload: any | null };

export type DispatchAction = { state: DcaProps; action: Action };

export interface DcaProps {
  amount: number;
  years: number;
  logarithm: boolean;
  frequency: Frequency;
  today: Date | Moment;
  prices: [];
  dca: [];
}
